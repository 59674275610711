<template>
  <a-result
    :title="`Top ${getFirstName}, we gaan de producten voor je verzenden!`"
  >
    <template #icon>
      <a-icon type="smile" theme="twoTone" />
    </template>
    <template #extra>
      <a-button type="primary" @click="$emit('next')"> Volgende </a-button>
    </template>
  </a-result>
</template>

<script>
import { mapGetters } from "vuex";
import { Result } from 'ant-design-vue';

export default {
  name: "Success",
  components: {
    AResult: Result,
  },
  beforeDestroy() {
    this.$emit("next");
  },
  computed: {
    ...mapGetters(["getFirstName"]),
  },
};
</script>

<style scoped>

</style>
