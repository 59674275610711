import { render, staticRenderFns } from "./CustomIcon.vue?vue&type=template&id=36c6bbde&scoped=true&"
import script from "./CustomIcon.vue?vue&type=script&lang=js&"
export * from "./CustomIcon.vue?vue&type=script&lang=js&"
import style0 from "./CustomIcon.vue?vue&type=style&index=0&id=36c6bbde&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36c6bbde",
  null
  
)

export default component.exports