<template>
  <a-modal
    :visible="visible"
    :centered="centered"
    :title="title"
    :closable="closable"
    @cancel="$emit('cancel')"
  >
    <template slot="footer">
      <a-button
        key="back"
        @click="$emit('cancel')"
        :disabled="disabledCloseBtn"
      >
        {{cancelText}}
      </a-button>
      <a-button
        key="submit"
        type="primary"
        :loading="loading"
        @click="$emit('ok')"
        :disabled="disabledOkBtn"
      >
        {{okText}}
      </a-button>
    </template>
    <slot></slot>
  </a-modal>
</template>

<script>
export default {
  name: "CustomModalWindow",
  props: {
    visible: Boolean,
    centered: {
      type: Boolean,
      default: true
    },
    cancelText: {
      type: String,
      default: 'Annuleren'
    },
    okText: {
      type: String,
      default: 'Ok'
    },
    title: {
      type: String,
      default: 'Modal window'
    },
    loading: {
      type: Boolean,
      default: false 
    },
    closable: {
      type: Boolean,
      default: false 
    },
    disabledCloseBtn: {
      type: Boolean,
      default: false 
    },
    disabledOkBtn: {
      type: Boolean,
      default: false 
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .ant-modal-title {
    font-size: 24px;
    font-weight: 600;
  }
</style>
