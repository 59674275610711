<template>
    <div
      v-show="needShowInput"
      class="formWrapper__form--inputWrapper"
    >
      <a-form-item :validate-status="(validation.$error) ? 'error' : 'success'">
        <template #help>
          <span v-show="validation.$error">
           {{ fieldData.dictionary }} onjuist
          </span>
        </template>
        <a-tooltip>
          <template
            v-if="fieldData.inputSettings.tooltip"
            #title
          >
            {{ fieldData.inputSettings.tooltip }}
          </template>
          <div>
            <a-select
              v-if="fieldData.inputSettings.type === 'Select'"
              size="large"
              style="width: 100%; background: transparent"
              :id="fieldName"
              v-model="fieldData.name"
              @focus="focusController(true)"
              @blur="focusController(false)"
              @change="$emit('change')"
            >
              <slot name="selectOptions">
                <a-select-option
                  v-for="option of fieldData.inputSettings.options"
                  :value="option.value"
                  :key="option.value + option.label"
                >
                  {{ option.label }}
                </a-select-option>
              </slot>
            </a-select>
            <a-auto-complete
              v-if="fieldData.inputSettings.type === 'Auto-complete-select'"
              style="width: 100%; background: transparent"
              size="large"
              :filter-option="filterOption"
              v-model="fieldData.name"
              @focus="focusController(true)"
              @blur="focusController(false)"
              optionLabelProp="label"
              @select="$emit('selectHandler', { key: $event })"
              @dropdownVisibleChange="$emit('dropdownVisibleChange', { bool: $event })"
              :ref="fieldName"
              :allowClear="allowClear"
            >
              <template slot="dataSource">
                <a-select-option
                  v-for="(item) in autoCompleteList"
                  :key="item.value + ''"
                  :value="item.value + ''"
                  :title="item.label"
                >
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <p style="margin: 0">
                      {{ item.label }}
                    </p>
                    <div>
                      <CustomIcon
                        v-if="isNeedEditTemplate"
                        type="edit"
                        @click="$emit('editTemplateHandler', item.value)" 
                      />
                      <a-icon
                        type="close"
                        style="color: orange; margin-left: 15px"
                        @click.stop="$emit('deleteListItem', { id: item.value })"
                      />
                    </div>
                  </div>
                </a-select-option>
              </template>
            </a-auto-complete>
            <a-input
              v-if="fieldData.inputSettings.type === 'Input'"
              allow-clear
              size="large"
              style="width: 100%; background: transparent"
              :id="fieldName"
              v-model="fieldData.name"
              @focus="focusController(true)"
              @blur="focusController(false)"
              @change="$emit('change')"
            >
            </a-input>
          </div>
        </a-tooltip>
      </a-form-item>
      <label
        :for="fieldName"
        class="formWrapper__form--label"
        :class="fieldData.isFocused ? 'focused' : ''"
      >
        {{ fieldData.dictionary }}
      </label>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import CustomIcon from "@/components/customComponents/CustomIcon.vue";

export default {
  name: "CustomInput",
  components: {
    CustomIcon,
  },
  props: {
    fieldData: Object,
    validation: Object,
    fieldName: String,
    formFields: {
      type: Object,
      required: true,
      default: () => ({})
    },
    autoCompleteList: {
      type: Array,
      default: () => ([])
    },
    isNeedEditTemplate: {
      type: Boolean,
      default: false
    },
    allowClear: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    focusController(toFocus) {
      this.$store.commit('FOCUS_HANDLER',
        {
          fieldData: this.fieldData,
          toFocus,
          validation: this.validation
        })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].children[0].children[0].text
          .toUpperCase()
          .indexOf(input.toUpperCase()) >= 0
      );
    },
  }, 
  computed: {
    needShowInput() {
      const conditions = this.fieldData.renderingCondition
      let isAllConditionPassed = true

      if (conditions.length) {
        conditions.forEach(condition => {
          let [field, bool] = condition.split(':')
          isAllConditionPassed = !!this.formFields[field].name === !!(+bool)
        })

        if (!isAllConditionPassed) {
          this.$store.commit('RESET_INPUT', { fieldData: this.fieldData, validation: this.validation })
        }
      }

      return isAllConditionPassed
    }
  }
}
</script>

<style scoped>

</style>
