export default {
    watch: {
        isFilledMainTemplateField(newValue, oldValue) {
            if (oldValue && !newValue) {
                this.clearAutofilledData({ step: this.outerField, fields: this.templateFields });
            }
        }
    },
    computed: {
        isFilledMainTemplateField() {
            return this.mainTemplateField;
        }
    }
}