<template>
  <a-icon
    :type="type"
    class="icon"
    @click.stop="$emit('click')"
  />
</template>

<script>
export default {
  name: "CustomIcon",
  props: {
    type: String,
  }
}
</script>

<style lang="scss" scoped>
    .icon{
       color: orange;
       transition: all 200ms ease-in;
    }
    .icon:hover {
        transform: scale(1.2);
    }
</style>
